<template>
  <div>
    <b-tabs
      v-if="$ability.can('manage', 'User')"
      fill
      pills
      class="shadow p-2"
    >
      <div class="mb-2">
        <b-link
          :to="{ name: 'startup-portal-details' }"
          class="btn btn-outline-primary btn-cart mr-2"
        >
          <plus-icon size="16" />
          Add Startup
        </b-link>
        <b-link
          class="btn btn-outline-primary btn-cart"
          @click="$bvModal.show('incubator-modal')"
        >
          <compass-icon size="16" />
          Incubator Manager
        </b-link>
        <b-modal
          id="incubator-modal"
          cancel-title="Cancel"
          title="Select Startup"
          no-close-on-backdrop
        >
          <v-select
            v-model="selectedOrgId"
            class="flex-fill"
            :close-on-select="true"
            :get-option-label="option => option.title"
            :options="startupOrgs"
            :reduce="option => option.users_associationtables[0].id"
            placeholder="Select Startup"
            style="cursor:pointer"
            @input="$router.replace(`/startup-portal/incubator/${selectedOrgId}/manage`)"
          />
        </b-modal>
      </div>
      <div class="d-flex justify-content-between mb-2">
        <h2>My Programs</h2>
        <b-link
          :to="`/startup-portal/programs`"
          class="btn btn-outline-primary"
        >
          <chevrons-right-icon size="16" />
        </b-link>
      </div>
      <b-overlay
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card
          v-if="programs.length < 1"
          class="text-center"
        >
          No programs found under your organizations!
        </b-card>
        <section class="grid-view">
          <b-row>
            <b-col
              v-for="(program, i) in programs"
              :key="i"
              :title="program.title"
              md="4"
            >
              <b-card
                :key="i"
                :title="program.title"
                class="ecommerce-card"
                img-alt="card img"
                img-top
                no-body
                style="border-radius: 10px; margin-bottom: 0; height: 100%;"
              >
                <div
                  class="px-2 py-1 bg-primary"
                  style="border-radius: 10px 10px 0 0;"

                >
                  <b-card-title class="text-white">
                    {{ program.title }}
                  </b-card-title>
                  <b-card-sub-title>
                    <span class="text-light">{{ program.type }}</span>
                  </b-card-sub-title>
                </div>
                <b-card-body class="px-2 py-1">
                  <b-card-text>
                    Begin date: {{ program.begin_date }}
                    <br> Industry: {{ program.industry }}
                  </b-card-text>
                </b-card-body>
                <!-- Product Actions -->
                <div class="item-options text-center d-flex">
                  <b-link
                    :to="`/startup-portal/programs/${program.id}`"
                    class="btn btn-wishlist btn-light"
                    style="width:100%"
                    variant="light"
                  >
                    <span>View Details</span>
                  </b-link>
                  <b-link
                    :to="`/startup-portal/programs/select/${program.id}`"
                    class="btn btn-primary btn-cart"
                    style="width:100%"
                  >
                    <span>Open</span>
                  </b-link>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </section>
      </b-overlay>
      <hr class="my-2">
      <div class="d-flex justify-content-between mb-2">
        <h2>Apply Now</h2>
        <b-link
          :to="`/startup-portal/apply-now`"
          class="btn btn-outline-primary"
        >
          <chevrons-right-icon size="16" />
        </b-link>
      </div>
      <b-overlay
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card
          v-if="applynow.length < 1"
          class="text-center"
        >
          No programs are currently open for application!
        </b-card>
        <section class="grid-view">
          <b-row>
            <b-col
              v-for="(applyn, i) in applynow"
              :key="i"
              :title="applyn.applyn"
              md="4"
            >
              <b-card
                :key="i"
                :title="applyn.title"
                class="ecommerce-card"
                img-alt="card img"
                img-top
                no-body
                style="border-radius: 10px; margin-bottom: 0; height: 100%;"
              >
                <div
                  class="px-2 py-1 bg-primary"
                  style="border-radius: 10px 10px 0 0;"

                >
                  <b-card-title class="text-white">
                    {{ applyn.title }}
                  </b-card-title>
                  <b-card-sub-title>
                    <span class="text-light">{{ applyn.type }}</span>
                  </b-card-sub-title>
                </div>
                <b-card-body class="px-2 py-1">
                  <b-card-text>
                    Begin date: {{ applyn.begin_date }}
                    <br> Industry: {{ applyn.industry }}
                  </b-card-text>
                </b-card-body>
                <!-- Product Actions -->
                <div class="item-options text-center d-flex">
                  <b-link
                    :to="`/startup-portal/programs/${applyn.id}`"
                    class="btn btn-wishlist btn-light"
                    variant="light"
                    style="width:100%"
                  >
                    <span>More Information</span>
                  </b-link>
                  <b-link
                    :to="`/startup-portal/application/${applyn.id}/${applyn.programs_applicationformstables[0].id}`"
                    class="btn btn-primary btn-cart"
                    style="width:100%"
                  >
                    <span>Apply Now</span>
                  </b-link>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </section>
      </b-overlay>
      <hr class="my-2">
      <div class="d-flex justify-content-between mb-2">
        <h2>My Applications</h2>
        <b-link
          :to="`/startup-portal/applications`"
          class="btn btn-outline-primary"
        >
          <chevrons-right-icon size="16" />
        </b-link>
      </div>
      <b-overlay
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card
          v-if="!$apollo.loading && applications.length < 1"
          class="text-center"
        >
          No applications found!
        </b-card>
        <section class="grid-view">
          <b-row>
            <b-col
              v-for="(application, j) in applications"
              :key="j"
              :title="application.title"
              md="4"
            >
              <b-card
                :key="j"
                :title="application.title"
                class="ecommerce-card"
                img-alt="card img"
                img-top
                no-body
                style="border-radius: 10px; margin-bottom: 0; height: 100%;"
              >
                <div
                  class="px-2 py-1 bg-primary"
                  style="border-radius: 10px 10px 0 0;"

                >
                  <b-card-title class="text-white">
                    {{ application.title }}
                  </b-card-title>
                  <b-card-sub-title>
                    <span class="text-light">{{ application.type }}</span>
                  </b-card-sub-title>
                </div>
                <b-card-body class="px-2 py-1">
                  <b-card-text>
                    Begin date: {{ application.begin_date }}
                    <br> Industry: {{ application.industry }}
                  </b-card-text>
                </b-card-body>
                <!-- Product Actions -->
                <div class="item-options text-center d-flex justify-content-end">
                  <b-link
                    :to="`/startup-portal/applications/rounds/${application.id}`"
                    class="btn btn-primary btn-cart"
                    style="width:50%"
                  >
                    <span>Open</span>
                  </b-link>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </section>
      </b-overlay>
    </b-tabs>
    <b-tabs
      v-else-if="$ability.can('manage', 'Mentor')"
      fill
      pills
      class="shadow p-2"
    >
      <div class="mb-2">
        <!-- <b-link
          v-if="incubators.length===1"
          :to="{ name: 'mentor-incubator-manager', params: { id: incubators[0].usersAssociationtablesByAssociatedOrg?incubators[0].usersAssociationtablesByAssociatedOrg[0].id:null } }"
          class="btn btn-outline-primary btn-cart"
        >
          <compass-icon size="16" />
          Incubator Manager

        </b-link> -->
      </div>
      <div class="d-flex justify-content-between mb-2">
        <h2>My Programs</h2>
        <b-link
          :to="`/mentor-portal/programs`"
          class="btn btn-outline-primary"
        >
          <chevrons-right-icon size="16" />
        </b-link>
      </div>
      <b-overlay
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card
          v-if="mentorprograms.length < 1"
          class="text-center"
        >
          No programs found under your organizations!
        </b-card>
        <section class="grid-view">
          <b-row>
            <b-col
              v-for="(mentorprogram, i) in mentorprograms"
              :key="i"
              :title="mentorprogram.title"
              md="4"
            >
              <b-card
                :key="i"
                :title="mentorprogram.title"
                class="ecommerce-card"
                img-alt="card img"
                img-top
                no-body
                style="border-radius: 10px; margin-bottom: 0; height: 100%;"
              >
                <div
                  class="px-2 py-1 bg-primary"
                  style="border-radius: 10px 10px 0 0;"

                >
                  <b-card-title class="text-white">
                    {{ mentorprogram.title }}
                  </b-card-title>
                  <b-card-sub-title>
                    <span class="text-light">{{ mentorprogram.type }}</span>
                  </b-card-sub-title>
                </div>
                <b-card-body class="p-2">
                  <b-card-text>
                    Begin date: {{ mentorprogram.begin_date }}
                    <br> Capacity: {{ mentorprogram.capacity }}
                    <br> Industry: {{ mentorprogram.industry }}
                  </b-card-text>
                </b-card-body>
                <!-- Product Actions -->
                <div class="item-options text-center d-flex justify-content-end">
                  <b-link
                    v-if="mentorprogram.programs_partnerstables[0].is_jury"
                    :to="`/mentor-portal/programs/rounds/${mentorprogram.id}/${mentorprogram.programs_partnerstables[0].id}`"
                    class="btn btn-wishlist btn-light"
                    variant="light"
                    style="width:50%"
                  >
                    <span>Applications</span>
                  </b-link>
                  <b-link
                    :to="`/mentor-portal/programs/select/${mentorprogram.id}/${mentorprogram.programs_partnerstables[0].id}`"
                    class="btn btn-primary btn-cart"
                    style="width:50%"
                  >
                    <span>Open</span>
                  </b-link>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </section>
      </b-overlay>
      <hr class="my-2">
      <div class="d-flex justify-content-between mb-2">
        <h2>Invitations</h2>
        <b-link
          :to="`/mentor-portal/invitations`"
          class="btn btn-outline-primary"
        >
          <chevrons-right-icon size="16" />
        </b-link>
      </div>
      <b-overlay
        :show="$apollo.loading || mutationLoading"
        spinner-variant="primary"
      >
        <b-card
          v-if="invitations.length < 1"
          class="text-center"
        >
          You don't have any pending invites!
        </b-card>
        <section class="grid-view">
          <b-row>
            <b-col
              v-for="(invitation, i) in invitations"
              :key="i"
              :title="invitation.title"
              md="4"
            >
              <b-card
                :key="i"
                :title="invitation.title"
                class="ecommerce-card"
                img-alt="card img"
                img-top
                no-body
              >
                <div
                  class="px-2 py-1 bg-primary"
                >
                  <b-card-title class="text-white">
                    {{ invitation.title }}
                  </b-card-title>
                  <b-card-sub-title>
                    <span class="text-light">{{ invitation.type }}</span>
                  </b-card-sub-title>
                </div>
                <b-card-body class="p-2">
                  <b-card-text>
                    Begin date: {{ invitation.begin_date }}
                    <br> Capacity: {{ invitation.capacity }}
                    <br> Industry: {{ invitation.industry }}
                  </b-card-text>
                </b-card-body>
                <!-- Product Actions -->
                <div class="item-options text-center d-flex">
                  <b-link
                    :to="`/mentor-portal/programs/${invitation.id}`"
                    class="btn btn-wishlist btn-light"
                    variant="light"
                    style="width:50%"
                  >
                    <span>View Details</span>
                  </b-link>
                  <b-link
                    v-if="invitation.users_associationtables[0].status.toLowerCase() === 'invited'"
                    class="btn btn-wishlist btn-primary"
                    variant="light"
                    style="width:50%"
                    @click="selectedIndex = i; $bvModal.show('respond-modal')"
                  >
                    <span>Respond</span>
                  </b-link>
                  <b-link
                    v-else
                    :class="{
                      'btn-success': invitation.users_associationtables[0].status.toLowerCase() === 'accept',
                      'btn-danger': invitation.users_associationtables[0].status.toLowerCase() === 'reject'
                    }"
                    class="btn btn-wishlist"
                    disabled
                    variant="light"
                    style="width:50%"
                    @click="selectedIndex = i; $bvModal.show('respond-modal')"
                  >
                    <span>{{ invitation.users_associationtables[0].status + 'ed' }}</span>
                  </b-link>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-modal
            id="respond-modal"
            cancel-title="Reject"
            ok-title="Accept"
            title="Respond to Invitation"
            @cancel="updateInvResponse(false)"
            @ok="updateInvResponse(true)"
          >
            <span
              v-if="selectedIndex !== null"
            >
              Would you like to join <strong class="text-primary">{{
                invitations[selectedIndex].title
              }}</strong> as a <strong

                class="text-primary"
              >{{ invitations[selectedIndex].users_associationtables[0].role }}</strong>?
            </span>
          </b-modal>
          <b-modal
            id="join-incubator-modal"
            class="font-medium-2"
            title="Join Incubator's Network"
            ok-title="Join"
            centered
            @ok="joinIncubator(incubators[0].id)"
          ><span
            v-if="incubators[0]"
          >Would you like to join the network of <strong class="text-primary d-inline-block">{{
            incubators[0].title
          }}</strong> as a mentor?</span>
          </b-modal>
        </section>
      </b-overlay>
    </b-tabs>
    <incubator-dashboard
      v-else-if="$ability.can('manage', 'Incubator')"
    />
    <span v-else>Some error occured. Please try logging out and log in again. </span>
  </div>
</template>

<script>

import { getUserData } from '@/auth/utils'
import { ChevronsRightIcon, CompassIcon, PlusIcon } from 'vue-feather-icons'
import gql from 'graphql-tag'
import {
  BCard,
  BCardBody,
  BCardSubTitle,
  BCardText,
  BCardTitle,
  BCol,
  BLink,
  BModal,
  BOverlay,
  BRow,
  BTabs,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    IncubatorDashboard: () => import('@/views/dashboard/v1/pages/IncubatorDashboard.vue'),
    BTabs,
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BOverlay,
    BLink,
    BCol,
    BRow,
    BModal,
    ChevronsRightIcon,
    PlusIcon,
    CompassIcon,
    vSelect,
  },
  beforeRouteLeave(to, from, next) {
    if (to.meta.resource === 'Auth' || getUserData().associatedOrgDetails) {
      next()
    }
    next(false)
  },
  data() {
    return {
      programs: [],
      applynow: [],
      applications: [],
      mentorprograms: [],
      invitations: [],
      incubators: [],
      startupincubators: [],
      startupOrgs: [],
      selectedIndex: null,
      userID: getUserData().id,
      mutationLoading: false,
      selectedOrgId: null,
      userOrgs: getUserData()
        .associatedOrgDetails

        .map(el => el.organization_id)
        .filter(n => n),
    }
  },
  methods: {
    joinIncubator(incubatorId) {
      this.mutationLoading = true
      const mutation = gql`
        mutation {
          insert_users_associationtable_one(
            object: {
              user_id: ${getUserData().id},
              incubator_id: ${incubatorId},
              role: "mentor",
            }
          ) {
            id
          }
        }
      `
      this.$apollo.mutate({
        mutation,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully joined incubator\'s network.',
              icon: 'CompassIcon',
              variant: 'success',
            },
          })

          this.mutationLoading = false
          this.$apollo.queries.incubators.refetch()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong. Please try again later.',
              message: error.message,
              icon: 'CompassIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
        })
    },
    updateResponse(status) {
      this.mutationLoading = true
      const stmt = [`
            update_users_associationtable_by_pk(pk_columns: {id: ${this.programs[this.selectedIndex].users_associationtables[0].id}}, _set: {status: "${status ? 'Accept' : 'Reject'}"}) {
              id
              status
            }`]
      // Update mentor table if accepted
      if (status === true) {
        stmt.push(`
            insert_programs_partnerstable_one(object: {user_id: ${this.userID}, program_id: ${this.programs[this.selectedIndex].id}, designation: ${this.programs[this.selectedIndex].users_associationtables[0].designation} }) {
              id
            }`)
      }
      this.$apollo.mutate({
        mutation: gql`mutation {${stmt.join('')}}`,
        update: (store, { data: { update_users_associationtable_by_pk } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: update_users_associationtable_by_pk.id ? 'Your response has been recorded' : 'Failed to submit response',
              icon: update_users_associationtable_by_pk.id ? 'CheckIcon' : 'XIcon',
              variant: update_users_associationtable_by_pk.id ? 'success' : 'danger',
            },
          })
          this.$apollo.queries.programs.refetch()
          window.location.reload()
          this.mutationLoading = false
        },
      })
    },
    updateInvResponse(status) {
      this.mutationLoading = true
      const stmt = [`
            update_users_associationtable_by_pk(pk_columns: {id: ${this.invitations[this.selectedIndex].users_associationtables[0].id}}, _set: {status: "${status ? 'Accept' : 'Reject'}"}) {
              id
              status
            }`]
      // Update mentor table if accepted
      if (status === true) {
        stmt.push(`
            insert_programs_partnerstable_one(object: {user_id: ${this.userID}, program_id: ${this.invitations[this.selectedIndex].id}, designation: ${this.invitations[this.selectedIndex].users_associationtables[0].designation}, is_jury: ${this.invitations[this.selectedIndex].users_associationtables[0].is_jury} }) {
              id
            }`)
      }
      this.$apollo.mutate({
        mutation: gql`mutation {${stmt.join('')}}`,
        update: (store, { data: { update_users_associationtable_by_pk } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: update_users_associationtable_by_pk.id ? 'Your response has been recorded' : 'Failed to submit response',
              icon: update_users_associationtable_by_pk.id ? 'CheckIcon' : 'XIcon',
              variant: update_users_associationtable_by_pk.id ? 'success' : 'danger',
            },
          })
          this.$apollo.queries.programs.refetch()
          this.$apollo.queries.invitations.refetch()
          window.location.reload()
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    programs: {
      query() {
        const urlPrefix = window.location.host.split('.')[0]
        const subdomain = (urlPrefix.startsWith('localhost') || urlPrefix.toLowerCase() === 'starworks') ? '.*' : urlPrefix
        return gql`
          {
          programs_basicinfo(order_by: {id: desc}, limit: 3, where: {users_organizationtable: {domain: {_regex: "${subdomain}"}}, type: {_in: ["Pre-incubation Program", "Incubation Program", "Accelerator Program"]}, programs_startupparticipants: {programs_applicantstable: {organization_id_id: {_in: [${this.userOrgs}]}}}}) {
            id
            title
            begin_date
            capacity
            industry
            fund_raising_assistance
            description
            status
            type
            users_organizationtable {
              logo
              title
              type
            }
          }
        }`
      },
      update: data => data.programs_basicinfo,
    },
    applynow: {
      query() {
        const urlPrefix = window.location.host.split('.')[0]
        const subdomain = (urlPrefix.startsWith('localhost') || urlPrefix.toLowerCase() === 'starworks') ? '.*' : urlPrefix
        return gql`
        {
          programs_basicinfo(order_by: {id: desc}, limit: 3, where: {users_organizationtable: {domain: {_regex: "${subdomain}"}}, programs_applicationformstables: {status: {_eq: "enabled"}, round_number: {_eq: 1}}, type: {_in: ["Pre-incubation Program", "Incubation Program", "Accelerator Program"]}}) {
            begin_date
            capacity
            description
            id
            image
            industry
            title
            type
            programs_applicationformstables(where: {round_number: {_eq: 1}, status: {_eq: "enabled"}}) {
              id
            }
          }
        }`
      },
      update: data => data.programs_basicinfo,
    },
    applications: {
      query() {
        const urlPrefix = window.location.host.split('.')[0]
        const subdomain = (urlPrefix.startsWith('localhost') || urlPrefix.toLowerCase() === 'starworks') ? '.*' : urlPrefix
        return gql`
        {
            programs_basicinfo(order_by: {id: desc}, limit: 3, where: {users_organizationtable: {domain: {_regex: "${subdomain}"}}, type: {_in: ["Pre-incubation Program", "Incubation Program", "Accelerator Program"]}, programs_applicantstables: {organization_id_id: {_in: [${this.userOrgs}]}}}) {
            id
            begin_date
            capacity
            description
            image
            industry
            organization_id_id
            title
            total_shortlisting_rounds
            type
          }
        }`
      },
      update: data => data.programs_basicinfo,
    },
    mentorprograms: {
      query() {
        const urlPrefix = window.location.host.split('.')[0]
        const subdomain = (urlPrefix.startsWith('localhost') || urlPrefix.toLowerCase() === 'starworks') ? '.*' : urlPrefix
        return gql`
          {
            programs_basicinfo(order_by: {id: desc}, limit: 3, where: {users_organizationtable: {domain: {_regex: "${subdomain}"}}, programs_partnerstables: { user_id: {_eq: ${getUserData().id} }}}) {
                id
                title
                begin_date
                capacity
                description
                industry
                type
                total_shortlisting_rounds
                status
                programs_partnerstables(where: {user_id: {_eq: ${getUserData().id}}}) {
                  id
                  is_jury
                }
              }
            }`
      },
      update: data => data.programs_basicinfo,
    },
    invitations: {
      query() {
        const urlPrefix = window.location.host.split('.')[0]
        const subdomain = (urlPrefix.startsWith('localhost') || urlPrefix.toLowerCase() === 'starworks') ? '.*' : urlPrefix
        return gql`
        {
            programs_basicinfo(order_by: {id: desc}, limit: 3, where: {users_organizationtable: {domain: {_regex: "${subdomain}"}}, users_associationtables: {user_id: {_eq: ${this.userID} }}}) {
              begin_date
              capacity
              id
              industry
              description
              type
              title
              status
              users_organizationtable {
                title
                url
              }
              users_associationtables(where: {user_id: {_eq: ${this.userID} }}) {
                id
                designation
                status
                role
                is_jury
              }
            }
          }`
      },
      update: data => {
        data.programs_basicinfo.forEach(program => {
          // eslint-disable-next-line no-unused-expressions,no-param-reassign
          program.users_associationtables[0].readonly = !(program.users_associationtables[0].status.toLowerCase() === 'invited')
        })
        return data.programs_basicinfo
      },
    },
    incubators: {
      query() {
        const urlPrefix = window.location.host.split('.')[0]
        const subdomain = (urlPrefix.startsWith('localhost') || urlPrefix.toLowerCase() === 'starworks') ? '.*' : urlPrefix
        return gql`
          query Incubators {
            users_organizationtable(where: {domain: {_regex: "${subdomain}"}, type: {_ilike: "incubator"}}) {
              id
              title
              official_email
              official_phone
              url
              city
              state
              industry
              startup_stage
              description
              usersAssociationtablesByAssociatedOrg(where: {user_id: {_eq: ${getUserData().id} }}) {
                id
                role
                status
              }
            }
          }`
      },
      update: data => {
        if (!data.users_organizationtable.usersAssociationtablesByAssociatedOrg) {
          // this.$bvModal.show('join-incubator-modal')
        }
        return data.users_organizationtable
      },
    },
    startupincubators: {
      query() {
        const urlPrefix = window.location.host.split('.')[0]
        const subdomain = (urlPrefix.startsWith('localhost') || urlPrefix.toLowerCase() === 'starworks') ? '.*' : urlPrefix
        return gql`
          query Incubators {
            users_organizationtable(where: {type: {_ilike: "incubator"}, domain: {_regex: "${subdomain}"}}) {
              id
              title
              official_email
              official_phone
              url
              city
              state
              industry
              startup_stage
              description
              usersAssociationtablesByAssociatedOrg(where: {organization_id: {_eq: ${this.selectedOrgId} }}) @include(if: ${!!this.selectedOrgId}) {
                id
                role
                status
              }
            }
          }`
      },
      update: data => data.users_organizationtable,
    },
    startupOrgs: {
      query() {
        const urlPrefix = window.location.host.split('.')[0]
        const subdomain = (urlPrefix.startsWith('localhost') || urlPrefix.toLowerCase() === 'starworks') ? '.*' : urlPrefix
        return gql`
          query startup_organizations {
          users_organizationtable(where: {users_associationtables: { role: {_eq: "startup"}, incubator_id: {_is_null: true}, user_id: {_eq: "${getUserData().id}"}}})
            {
              id
              title
              users_associationtables(where: {usersOrganizationtableByIncubator: {domain: {_regex: "${subdomain}"}}, role: { _in: ["startup","incubatee","preincubatee","graduate"] }}) {
                id
              }
            }
        }`
      },
      update: data => {
        const temp = []
        data.users_organizationtable.forEach(orgs => {
          if (orgs.users_associationtables.length > 0) {
            temp.push(orgs)
          }
        })
        return temp
      },
    },
  },
}
</script>
